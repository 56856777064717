module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"mdPlugins":[null,null],"globalScope":"\n        import { InlineMath } from 'react-katex';\n        import { BlockMath as MathBlock } from 'react-katex';\n        \n        export default { InlineMath, MathBlock };\n        ","gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-mdx-images","options":{"withWebp":{"quality":90},"maxWidth":712}}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:300,300i,400,400i,500,500i:latin,latin-ext","Roboto Mono:300,300i,400,400i,500,500i:latin,latin-ext","Gentium Book Basic:400,400i,700,700i:latin,latin-ext"]}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
